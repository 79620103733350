import React from "react";
import { Link } from "gatsby";

class Header extends React.Component {
  componentDidMount() {
    (() => {
      const sideNav = document.querySelector(".nav-list");
      const toggleNavBtn = document.querySelector(".toggle-nav");
      const burger = document.querySelector(".burger");
      const pageOverlay = document.querySelector(".overlay");
      const navLinks = document.querySelectorAll(".link");
      const body = document.querySelector("body");
      const html = document.querySelector("html");

      // Disable page scroll
      function disablePageScroll() {
        if (sideNav.classList.contains("-open")) {
          body.classList.add("_disableScroll");
          html.classList.add("_disableScroll");
        } else {
          body.classList.remove("_disableScroll");
          html.classList.remove("_disableScroll");
        }
      }

      // Nav funtion (toggle)
      function toggleNav() {
        sideNav.classList.toggle("-open");
        burger.classList.toggle("open");
        disablePageScroll();
      }

      // To default
      function toDefaults() {
        // Close nav menu
        sideNav.classList.remove("-open");
        burger.classList.remove("open");
        // Make sure scrolling is enabled
        body.classList.remove("_disableScroll");
        html.classList.remove("_disableScroll");
      }

      // Event listeners
      toggleNavBtn.addEventListener("click", toggleNav);
      pageOverlay.addEventListener("click", toggleNav);

      // (on mobile) close nav menu when link is clicked
      // this is useful on mobile when clicking an anchor tag on the current page (eg. index.html#last-section)
      navLinks.forEach((el) => {
        el.addEventListener("click", (event) => {
          toDefaults();
        });
      });

      // when browser is resized (past breakpoint) reset to defaults
      (function () {
        window.addEventListener("resize", resizeThrottler, false);
        let resizeTimeout;

        function actualResizeHandler() {
          // handle the resize event

          // Window resized width
          const width = window.innerWidth;

          // If resized greater than BREAKPOINT (default: 800px), then reset nav functions
          if (width >= 800) {
            toDefaults();
          }
        }
        function resizeThrottler() {
          // ignore resize events as long as an actualResizeHandler execution is in the queue
          if (!resizeTimeout) {
            resizeTimeout = setTimeout(function () {
              resizeTimeout = null;
              actualResizeHandler();

              // The actualResizeHandler will execute at a rate of 15fps
            }, 66);
          }
        }
      })();
    })();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <>
        <header className="nav-wrapper">
          <nav className="nav">
            <button className="toggle-nav" type="button">
              <div className="burger -squeeze -offset" type="button">
                <span className="burger-lines" />
              </div>
            </button>
            <Link className="logo-link" to="/">
              <img
                className="logo"
                id="logo"
                src="/logos/logo-48.png"
                alt="Logo"
              />
            </Link>
            <ul className="nav-list" role="navigation">
              <div className="list -left">
                <li className="item">
                  <Link className="link" to="/about">
                    About
                  </Link>
                </li>
                <li className="item">
                  <Link className="link" to="/categories/spiritual">
                    Spiritual
                  </Link>
                </li>
                <li className="item">
                  <Link className="link" to="/categories/tech">
                    Tech
                  </Link>
                </li>
                <li className="item">
                  <a className="link" href="/categories/culture">
                    Culture
                  </a>
                </li>
                <li className="item">
                  <a className="link" href="/categories/life">
                    Life
                  </a>
                </li>
                <li className="item">
                  <a className="link" href="/categories/collections">
                    Collections
                  </a>
                </li>
              </div>
              <div className="list -right">
                <div className="overlay" />
              </div>
            </ul>
          </nav>
        </header>
      </>
    );
  }
}

export default Header;

import React from "react";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Header from "./Header";
import "./normalize.css";
import "./index.css";

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="layout-container">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto+Slab:700|Open+Sans:400,700"
          />
          <link href="/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/vendors/nav/nav.css"
          />
        </Helmet>
        <Header />
        <main className="main-wrapper">{children}</main>
      </div>
    );
  }
}
